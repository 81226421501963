import React from 'react';
import { useTranslation } from 'react-i18next';
import SEO from '../components/seo';
import Layout from '../components/Layout';
import styled from 'styled-components';
import { Container } from 'ui/containers';
import backgroundProfil from '../assets/images/backgroundProfil.jpg';
import { useAsync } from 'react-async';
import { getGeneralInformations } from 'api/general';
import Content from 'components/Content';

const Picture = styled.div`
  background-image: url("${backgroundProfil}");
  background-size: 100%;
  height: 350px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
`;

const CGV = () => {
  const { t } = useTranslation();

  const { data: { generalInformation } = {} } = useAsync({
    promiseFn: getGeneralInformations,
  });

  const { cgv } = generalInformation || {};

  return (
    <Layout>
      <SEO title={t('pageTitle.cgv')} />
      <Picture />
      <Container>
        <Content html={cgv} />
      </Container>
    </Layout>
  );
};

export default CGV;
